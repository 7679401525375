import React from "react"
import PageHeader from "../components/PageHeader"
import Layout from "../components/Layout"
import Container from "../components/Container"
import PageDivider from "../components/PageDivider"
import ThreeColumns from "../components/ThreeColumns"
import SectionHeading from "../components/SectionHeading"
import IllustratedContent from "../components/IllustratedContent"
import SEO from "../components/seo"
import background from "../images/cup.png"
import wash from "../images/wash.png"
import fold from "../images/fold.png"
import insert from "../images/insert.png"
import remove from "../images/remove.png"
import clean from "../images/clean.png"

const Cup = () => {
  return (
    <Layout>
      <SEO title="Menstrual Cup" />
      <PageHeader
        header="The Menstrual Cup"
        subHeader="1 Menstrual cup = 600 days of safe, dignified & life changing menstruation."
        background={background}
      />
      <Container size="m">
        <ThreeColumns
          columns={[
            {
              title: "Simple",
              content:
                "An easy to use device after correct explanation of the product the cup is simple and user friendly.",
            },
            {
              title: "Safe",
              content:
                "The use of the cup elimates the use of other materials and unhygienic replacements. In developing countries all over the world, women use mud, chicken feathers and old towels as a substitute.",
            },
            {
              title: "Sustainable",
              content:
                "One cup gifts a women 600 days of safe and dignified menstruation. One cup can be utilized for up to ten years.",
            },
          ]}
        />
        <SectionHeading title="How to use">
          The menstrual cup is a simple and easy to use device that provides up
          to ten years of safe menstruation.
        </SectionHeading>
        <IllustratedContent number="1" heading="Wash" image={wash}>
          The most important step! Wash your hands and the cup with cooled
          boiling water.
        </IllustratedContent>
        <IllustratedContent
          number="2"
          heading="Fold and hold"
          align="right"
          image={fold}
        >
          <p>
            Fold the cup using one of the following options and hold in place.
          </p>
          <ul>
            <li>
              <b>C Fold:</b> Fold cup in half symmetrically.
            </li>
            <li>
              <b>Pinch Down:</b> Pull edge of cup down with index finger.
            </li>
            <li>
              <b>7 fold:</b> Fold down the edge of the cup diagonally.
            </li>
          </ul>
        </IllustratedContent>
        <IllustratedContent
          number="3"
          heading="Insert"
          showConnection={false}
          image={insert}
        >
          Try to relax. For easiest insertion, lift one leg or squat and gently
          insert the cup with the stem facing downwards.
        </IllustratedContent>
        <PageDivider>
          Depending on your flow, wash and reinsert every 4 - 8 hours.
        </PageDivider>
        <IllustratedContent
          number="4"
          heading="Remove"
          align="right"
          image={remove}
        >
          Wash your hands. Slowly pull the stem on the base of the cup. Pinch
          the base of the cup to release the seal and gently pull down.
        </IllustratedContent>
        <IllustratedContent
          number="5"
          heading="Clean"
          showConnection={false}
          image={clean}
        >
          Empty and wash the cup. Then store in a clean place.
        </IllustratedContent>
      </Container>
    </Layout>
  )
}

export default Cup
